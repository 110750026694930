import "./App.css";
import { useState } from "react";
import { motion } from "framer-motion";
import "./styles.css";
import AnimatedText from "./AnimatedText";
import Logo from "./shared/assets/logo.svg";

function App() {
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => setIsOn(!isOn);
    // Placeholder text data, as if from API
    const placeholderText = [
      { type: "heading1", text: "disruptive_thinking" },
      {
        type: "heading2",
        text: "Dreihandel"
      }
    ];
  
    const container = {
      visible: {
        transition: {
          staggerChildren: 0.025
        }
      }
    };

  return (
    <div className="App">
      {/* {isOn && <h2>disruptive | thinking</h2>} */}
      <div className="switch" data-isOn={isOn} onClick={toggleSwitch}>
        <motion.div className="handle" layout transition={spring} />
      </div>
      {/* {isOn && <h1>Dreihandel</h1>} */}
      <motion.div
      className="App"
      initial="hidden"
      // animate="visible"
      animate={isOn ? "visible" : "hidden"}
      variants={container}
    >
      <div className="container">
        {placeholderText.map((item, index) => {
          return <AnimatedText {...item} key={index} />;
        })}
      </div>
    </motion.div>
    <img className="logo" src={Logo} alt="Logo Dreihandel GmbH" />
    </div>
  );
}

export default App;
